import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface ContactEmail {
    sender:  string;
    subject: string;
    body:    string;
  }

export interface ContactState extends ContactEmail {
  status: 'initialized' | 'submitting' | 'succeeded' | 'failed';
}

const initialState: ContactState = {
  sender: '',
  subject: '',
  body: '',

  status: 'initialized',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const submitEmail = createAsyncThunk(
    'contact/submitEmail',
    async (email: ContactEmail) => {
        const endpoint: string = process.env.REACT_APP_CONTACT_API_ENDPOINT || 'https://set.up/your/env';

        const response = await fetch(endpoint, {
            method: 'POST',
            credentials: 'omit',
            body: JSON.stringify({
                'sender':  email.sender,
                'subject': email.subject,
                'text':    email.body
            })
        });
        
        console.log(response);
        return response.status;
    // const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    // return response.data;
    }
);

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeEmail: (state, action: PayloadAction<string>) => {
            state.sender = action.payload;
        },
        changeSubject: (state, action: PayloadAction<string>) => {
            state.subject = action.payload;
        },
        changeEmailBody: (state, action: PayloadAction<string>) => {
            state.body = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(submitEmail.pending, (state) => {
                state.status = 'submitting';
            })
            .addCase(submitEmail.fulfilled, (state, action) => {
                console.log(state);
                console.log(action);
                state.status = (action.payload === 200) ? 'succeeded' : 'failed';
            });
    },
});

export const { changeEmail, changeSubject, changeEmailBody } = contactSlice.actions;

export const selectSubmissionStatus = (state: RootState) => state.contact.status;

export default contactSlice.reducer;
