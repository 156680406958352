import React from 'react';

import { Contact } from './features/contact/Contact';

function App() {
  return (
    <Contact />
  );
}

export default App;
